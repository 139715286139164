import { FaGamepad, FaSpinner } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { startGameSession } from '../api/easeliteApi';
import { useNavigate } from 'react-router-dom';

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #2d3250;
  min-height: 100vh;
  color: #b9b9b9;
  position: relative;
  overflow: hidden;
`;

const BackgroundPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(#503e9d 2px, transparent 2px),
    radial-gradient(#503e9d 2px, transparent 2px);
  background-size: 50px 50px;
  background-position: 0 0, 25px 25px;
  opacity: 0.1;
`;

const ContentWrapper = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  color: #f9cb5c;
  font-size: 3rem;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: ${floatAnimation} 3s ease-in-out infinite;
`;

const Input = styled.input`
  margin: 1rem 0;
  padding: 0.8rem 1.2rem;
  font-size: 1rem;
  width: 300px;
  border: none;
  border-radius: 25px;
  background-color: #503e9d;
  color: #b9b9b9;
  text-align: center;
  transition: all 0.3s ease;
  &::placeholder {
    color: #b9b9b9;
    opacity: 0.7;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(249, 203, 92, 0.5);
    transform: scale(1.05);
  }
`;

const Button = styled.button`
  background-color: #f9cb5c;
  color: #2d3250;
  border: none;
  padding: 0.8rem 1.5rem;
  margin: 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 25px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #503e9d;
    color: #f9cb5c;
    transform: scale(1.05);
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const GameOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  padding: 2rem;
  background-color: #503e9d;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-10px);
  }
`;

const GameTitle = styled.h3`
  color: #f9cb5c;
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.p`
  color: #f9cb5c;
  font-weight: bold;
  margin-top: 1rem;
  background-color: rgba(249, 203, 92, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 10px;
`;

const GamepadIcon = styled(FaGamepad)`
  font-size: 3rem;
  color: #f9cb5c;
  margin-bottom: 1rem;
`;

const LoadingSpinner = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const GameSelection: React.FC = () => {
  const [easeliteGameId, setEaseliteGameId] = useState('');
  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [gameToken, setGameToken] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleValidation = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await startGameSession(easeliteGameId);
      setGameToken(response.gameToken); // Store the token
      setIsValidated(true);
    } catch (error) {
      console.error('Error starting game session:', error);
      setError('Invalid Easelite Game ID/ Unable to start session You might have reached your daily limit. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartMiniGames = () => {
    if (gameToken) {
      navigate('/mini-games', { state: { gameToken } });
    } else {
      setError('Game token is missing. Please start a new session.');
    }
  };

  useEffect(() => {
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.fontFamily = "'Roboto', sans-serif";
  }, []);

  if (!isValidated) {
    return (
      <Container>
        <BackgroundPattern />
        <ContentWrapper>
          <Title>Welcome to Easelite Games</Title>
          <GamepadIcon />
          <Input
            type="text"
            value={easeliteGameId}
            onChange={(e) => setEaseliteGameId(e.target.value)}
            placeholder="Enter Easelite Game ID"
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Button onClick={handleValidation} disabled={isLoading}>
            {isLoading ? <LoadingSpinner /> : 'Start Session'}
          </Button>
        </ContentWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <BackgroundPattern />
      <ContentWrapper>
        <Title>Choose Your Adventure</Title>
        <GameOption>
          <GamepadIcon />
          <GameTitle>Mini-Games Challenge</GameTitle>
          <Button onClick={handleStartMiniGames}>
            Play Now
          </Button>
        </GameOption>
      </ContentWrapper>
    </Container>
  );
};

export default GameSelection;
