import React from 'react';
import { useLocation } from 'react-router-dom';

const ScoreBreakdown = () => {
  const location = useLocation();
  const scores = location.state?.scores || []; // Get scores from state

  return (
    <div>
      <h1>Score Breakdown</h1>
      <ul>
        {scores.map((score: number, index: number) => ( // Explicitly define score and index types
          <li key={index}>Game {index + 1}: {score}</li> // Display each score
        ))}
      </ul>
      <button onClick={() => {/* Logic to record scores */}}>Record Scores</button>
    </div>
  );
};

export default ScoreBreakdown;

// Add this line to ensure it's treated as a module
export {};
