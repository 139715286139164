import React, { useEffect, useState } from 'react';
import { getMonthlyLeaderboard, getMyRank, getWeeklyLeaderboard } from '../api/easeliteApi';

import { LeaderboardEntry } from '../types/apiTypes';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem;
  margin-top: 60px;
  // Add these lines for debugging
  border: 1px solid red;
  min-height: 300px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  text-align: left;
  padding: 0.5rem;
  border-bottom: 1px solid white;
`;

const Td = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const ToggleButton = styled.button`
  background-color: #4a5680;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #5a6a9f;
  }
`;

const ExpandableRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const IndividualScores = styled.td`
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
`;

const UserRankSection = styled.div`
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
`;

const Input = styled.input`
  padding: 0.5rem;
  margin-right: 1rem;
  border: none;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
`;

const Button = styled.button`
  background-color: #4a5680;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #5a6a9f;
  }
`;

const RankDisplay = styled.div`
  margin-top: 1rem;
  font-size: 1.1rem;
`;

const Leaderboard: React.FC = () => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [leaderboardType, setLeaderboardType] = useState<'weekly' | 'monthly'>('weekly');
  const [expandedEntry, setExpandedEntry] = useState<number | null>(null);
  const [gameId, setGameId] = useState('');
  const [userRank, setUserRank] = useState<{ weekRank: number | null; monthRank: number | null } | null>(null);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        setLoading(true);
        let data;
        if (leaderboardType === 'weekly') {
          data = await getWeeklyLeaderboard();
        } else {
          data = await getMonthlyLeaderboard();
        }
        console.log('Leaderboard data:', data); // Add this line for debugging
        setLeaderboard(data.highScores);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching leaderboard:', err); // Add this line for debugging
        setError('Failed to fetch leaderboard data');
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, [leaderboardType]);

  const toggleLeaderboardType = () => {
    setLeaderboardType(prevType => prevType === 'weekly' ? 'monthly' : 'weekly');
  };

  const toggleExpand = (index: number) => {
    setExpandedEntry(expandedEntry === index ? null : index);
  };

  const handleGameIdSubmit = async () => {
    if (gameId) {
      try {
        const rankData = await getMyRank(gameId);
        setUserRank(rankData);
      } catch (err) {
        console.error('Error fetching user rank:', err);
        setError('Failed to fetch user rank');
      }
    }
  };

  return (
    <Container>
      <UserRankSection>
        <h3>Check Your Rank</h3>
        <Input
          type="text"
          placeholder="Enter your Easelite Game ID"
          value={gameId}
          onChange={(e) => setGameId(e.target.value)}
        />
        <Button onClick={handleGameIdSubmit}>Get Rank</Button>
        {userRank && (
          <RankDisplay>
            <p>Your Weekly Rank: {userRank.weekRank !== null ? userRank.weekRank : 'N/A'}</p>
            <p>Your Monthly Rank: {userRank.monthRank !== null ? userRank.monthRank : 'N/A'}</p>
          </RankDisplay>
        )}
      </UserRankSection>

      <h2>{leaderboardType === 'weekly' ? 'Weekly Leaderboard' : 'Monthly Leaderboard'}</h2>
      <ToggleButton onClick={toggleLeaderboardType}>
        Switch to {leaderboardType === 'weekly' ? 'Monthly' : 'Weekly'} Leaderboard
      </ToggleButton>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <Th>Rank</Th>
              <Th>Player</Th>
              <Th>University</Th>
              <Th>Score</Th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((entry, index) => (
              <React.Fragment key={index}>
                <ExpandableRow onClick={() => toggleExpand(index)}>
                  <Td>{entry.rank}</Td>
                  <Td>{entry.name}</Td>
                  <Td>{entry.universityName}</Td>
                  <Td>{entry.score}</Td>
                </ExpandableRow>
                {expandedEntry === index && (
                  <tr>
                    <IndividualScores colSpan={4}>
                      <h4>Individual Scores:</h4>
                      <p>Dash 2: {entry.individualScores.dash2}</p>
                      {/* <p>Number Memory: {entry.individualScores.numberMemory}</p> */}
                      <p>Discount Dash: {entry.individualScores.discountDash}</p>
                      <p>Pattern Recognition: {entry.individualScores.patternRecognition}</p>
                      <p>Wheel of Fortune: {entry.individualScores.wheelOfFortune}</p>
                      <p>Reaction Time: {entry.individualScores.reactionTime}</p>
                    </IndividualScores>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default Leaderboard;
