import React from 'react';
import styled from 'styled-components';

interface PreGameInstructionsProps {
  totalGames: number;
  currentGameIndex: number;
}

const Container = styled.div`
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  color: #ffffff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #ffd700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: 1.6;
`;

const Highlight = styled.span`
  color: #00ffcc;
  font-weight: bold;
`;

const GameCount = styled.div`
  font-size: 1.5rem;
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
`;

const PreGameInstructions: React.FC<PreGameInstructionsProps> = ({ totalGames, currentGameIndex }) => {
  return (
    <Container>
      <Title>Welcome to the Mini Games Challenge!</Title>
      <Paragraph>
        You will play <Highlight>{totalGames}</Highlight> exciting games in total.
      </Paragraph>
      <Paragraph>
        Each game consists of <Highlight>3 rounds</Highlight>, increasing in difficulty and rewards.
      </Paragraph>
      <Paragraph>
        Every game has its own set of instructions, so read carefully!
      </Paragraph>
      <Paragraph>
        Your scores from all games will be combined for an epic final score.
      </Paragraph>
      <Paragraph>
        Get ready to challenge yourself, have fun, and aim for the high score!
      </Paragraph>
      
    </Container>
  );
};

export default PreGameInstructions;
