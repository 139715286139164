import CryptoJS from 'crypto-js';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Replace with actual API URL
interface GameSessionResponse {
  gameToken: string;
  message: string;
}

export const startGameSession = async (
  easeliteGameId: string
): Promise<GameSessionResponse> => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/games/start-game-session`,
      { easeliteGameId }
    );
    return response.data;
  } catch (error) {
    console.error('Error starting game session:', error);
    throw error;
  }
};

interface ScoreData {
  discountDash: number;
  dash2: number;
  reactionTime: number;
  patternRecognition: number;
  wheelOfFortune: number;
  // numberMemory: number;
  all: number;
}

const encryptScore = (score: number): string => {
  const data = {
    score: score,
    timestamp: Date.now(),
  };
  return btoa(JSON.stringify(data));
};

export const registerScore = async (
  scoreData: ScoreData,
  gameToken: string
): Promise<void> => {
  try {
    const encryptedData = encryptScore(scoreData.all);
    await axios.post(`${API_BASE_URL}/games/leaderboard/register`, {
      ...scoreData,
      gameToken,
      encryptedData,
    });
  } catch (error) {
    console.error('Error registering score:', error);
    throw error;
  }
};

interface LeaderboardEntry {
  rank: number;
  name: string;
  universityName: string;
  score: number;
  individualScores: {
    dash2: number;
    // numberMemory: number;
    discountDash: number;
    patternRecognition: number;
    reactionTime: number;
    wheelOfFortune: number;
  };
}

interface LeaderboardResponse {
  highScores: LeaderboardEntry[];
}

export const getWeeklyLeaderboard = async (): Promise<LeaderboardResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/games/leaderboard/week`);
    return response.data;
  } catch (error) {
    console.error('Error fetching weekly leaderboard:', error);
    throw error;
  }
};

export const getMonthlyLeaderboard = async (): Promise<LeaderboardResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/games/leaderboard/month`);
    return response.data;
  } catch (error) {
    console.error('Error fetching monthly leaderboard:', error);
    throw error;
  }
};

interface RankResponse {
  weekRank: number | null;
  monthRank: number | null;
}

export const getMyRank = async (gameId: string): Promise<RankResponse> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/games/myrank`, {
      params: { gameId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user rank:', error);
    throw error;
  }
};

export interface CountryMatch {
  country: string;
  capital: string;
}

export interface AnimalMatch {
  animal: string;
  habitat: string;
}

type EducationalMatch = CountryMatch | AnimalMatch;

interface EducationalMatchesResponse {
  matches: EducationalMatch[];
}

export const getEducationalMatches = async (
  category: 'animals' | 'capitals',
  count: number = 10
): Promise<EducationalMatch[]> => {
  try {
    const response = await axios.get<EducationalMatch[]>(
      `${API_BASE_URL}/games/educational-matches`,
      {
        params: { category, count },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching educational matches:', error);
    throw error;
  }
};
