import { FaBolt, FaClock, FaTrophy } from 'react-icons/fa';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { GameProps } from '../../types/GameProps';
import Instructions from '../../components/Instructions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
  background-color: #1e2238;
  color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h2`
  color: #00fff5;
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const GameInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
  background-color: #2a3150;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ButtonGrid = styled.div<{ round: number }>`
  display: grid;
  grid-template-columns: ${props => 
    props.round === 1 ? 'repeat(3, 1fr)' : 
    props.round === 2 ? 'repeat(4, 1fr)' : 
    'repeat(5, 1fr)'
  };
  gap: ${props => props.round === 3 ? '10px' : '15px'};
  margin-top: 20px;
  max-width: ${props => props.round === 3 ? '500px' : '400px'};

  @media (max-width: 768px) {
    gap: 10px;
    max-width: 100%;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const Button = styled.button<{ status: 'waiting' | 'active' | 'clicked'; round: number }>`
  width: ${props => props.round === 3 ? '60px' : '80px'};
  height: ${props => props.round === 3 ? '60px' : '80px'};
  font-size: ${props => props.round === 3 ? '14px' : '16px'};
  background-color: ${props => 
    props.status === 'waiting' ? '#e94560' : 
    props.status === 'active' ? '#4caf50' : 
    '#4a5680'
  };
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: scale(0.95);
  }

  ${props => props.status === 'active' && css`
    animation: ${pulse} 0.5s infinite;
  `}

  @media (max-width: 768px) {
    width: 100%;
    height: 80px;
    font-size: 14px;
  }
`;

const NextButton = styled.button`
  background-color: #e94560;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  margin-top: 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  &:hover {
    background-color: #ff6b6b;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(233, 69, 96, 0.4);
  }
`;

const ResultContainer = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: #2a3150;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ReactionTime: React.FC<GameProps> = ({ onScoreUpdate, onNextGame, totalGames, currentGameIndex, scores }) => {
  const [buttons, setButtons] = useState<Array<{ id: number; status: 'waiting' | 'active' | 'clicked'; activationTime: number }>>([]);
  const [round, setRound] = useState(1);
  const [score, setScore] = useState(0);
  const [isRoundComplete, setIsRoundComplete] = useState(false);
  const [roundScores, setRoundScores] = useState<number[]>([]);
  const timeoutsRef = useRef<ReturnType<typeof setTimeout>[]>([]);

  const calculateRoundWeight = (round: number) => {
    const weights = [0.2, 0.35, 0.45]; // 20% for round 1, 35% for round 2, 45% for round 3
    return weights[round - 1];
  };

  const calculateButtonWeight = (round: number) => {
    const buttonsPerRound = [3, 8, 15]; // Number of buttons in each round
    return 1 / buttonsPerRound[round - 1];
  };

  const initializeRound = useCallback(() => {
    timeoutsRef.current.forEach(clearTimeout);
    timeoutsRef.current = [];

    const numButtons = round === 1 ? 3 : round === 2 ? 8 : 15;
    const newButtons = Array.from({ length: numButtons }, (_, index) => ({
      id: index,
      status: 'waiting' as const,
      activationTime: 0
    }));
    setButtons(newButtons);
    setIsRoundComplete(false);

    const shuffledButtons = [...newButtons].sort(() => Math.random() - 0.5);

    shuffledButtons.forEach((button) => {
      const randomDelay = Math.random() * 3000 + 1000;
      const timeout = setTimeout(() => {
        setButtons(prev => prev.map(b => 
          b.id === button.id && b.status === 'waiting' ? { ...b, status: 'active', activationTime: Date.now() } : b
        ));
      }, randomDelay);
      timeoutsRef.current.push(timeout);
    });
  }, [round]);

  useEffect(() => {
    initializeRound();
    return () => {
      timeoutsRef.current.forEach(clearTimeout);
    };
  }, [initializeRound]);

  const handleButtonClick = (id: number) => {
    const clickedButton = buttons.find(b => b.id === id);
    if (clickedButton && clickedButton.status === 'active') {
      const reactionTime = (Date.now() - clickedButton.activationTime) / 1000;
      const maxReactionTime = 2;
      const normalizedScore = Math.max(0, 1 - reactionTime / maxReactionTime);
      const roundWeight = calculateRoundWeight(round);
      const buttonWeight = calculateButtonWeight(round);
      const maxButtonScore = Math.round(1500 * roundWeight * buttonWeight);
      const buttonScore = Math.round(normalizedScore * maxButtonScore);
      
      console.log(`Round: ${round}, Button ID: ${id}, Reaction Time: ${reactionTime.toFixed(3)}s, Button Score: ${buttonScore}`);

      setScore(prev => {
        const newScore = prev + buttonScore;
        console.log(`Updated Round Score: ${newScore}`);
        return newScore;
      });

      setButtons(prev => {
        const newButtons = prev.map(b => 
          b.id === id ? { ...b, status: 'clicked' as const } : b
        );
        if (newButtons.every(b => b.status === 'clicked')) {
          setIsRoundComplete(true);
          setRoundScores(prev => [...prev, score + buttonScore]);
        }
        return newButtons;
      });
    }
  };

  const handleNextRound = () => {
    if (round < 3) {
      setRound(prev => prev + 1);
      setScore(0);
      console.log(`Starting Round ${round + 1}`);
      initializeRound();
    } else {
      const totalScore = Math.round(roundScores.reduce((sum, score) => sum + score, 0));
      console.log(`Game Finished. Round Scores: ${roundScores.join(', ')}, Total Score: ${totalScore}`);
      onScoreUpdate(totalScore);
      onNextGame();
    }
  };

  return (
    <Container>
      <Title><FaBolt /> Lightning Reflexes - Round {round}</Title>
      <Instructions text="Click the buttons as soon as they turn green! Faster reactions = higher score." />
      <Instructions text="Each round adds more buttons and increases difficulty. Stay focused and aim for quick, accurate clicks!" />
      <Instructions text={`You have ${totalGames - currentGameIndex - 1} games left to play!`} />
      <GameInfo>
        <InfoItem>
          <FaTrophy />
          <span>Score: {score}</span>
        </InfoItem>
        <InfoItem>
          <FaClock />
          <span>Round: {round} / 3</span>
        </InfoItem>
        <InfoItem>
          <FaTrophy />
          <span>Total: {roundScores.reduce((sum, score) => sum + score, 0) + score}</span>
        </InfoItem>
      </GameInfo>
      <ButtonGrid round={round}>
        {buttons.map(button => (
          <Button
            key={button.id}
            status={button.status}
            round={round}
            onClick={() => handleButtonClick(button.id)}
            disabled={button.status === 'clicked'}
          >
            {button.status === 'waiting' ? 'Wait...' : button.status === 'active' ? 'Click!' : 'Done'}
          </Button>
        ))}
      </ButtonGrid>
      {isRoundComplete && (
        <ResultContainer>
          <h4>Round Complete!</h4>
          <p>Round Score: {roundScores[round - 1]}</p>
          <NextButton onClick={handleNextRound}>
            {round < 3 ? 'Next Round' : 'Finish Game'}
          </NextButton>
        </ResultContainer>
      )}
    </Container>
  );
};

export default ReactionTime;
