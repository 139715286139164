import { LeaderboardEntry, RankResponse, ScoreData } from '../../types/apiTypes';
import React, { useEffect, useState } from 'react';
import { getMonthlyLeaderboard, getMyRank, getWeeklyLeaderboard, registerScore } from '../../api/easeliteApi';
import { useLocation, useNavigate } from 'react-router-dom';

import AimTrainer from './AimTrainer';
import Dash2 from './Dash2';
import DiscountDash from './DiscountDash';
import FortunesWheel from './FortunesWheel';
import NumberMemory from './NumberMemory';
import PatternRecognition from './PatternRecognition';
import PreGameInstructions from '../../components/PreGameInstructions';
import ReactionTime from './ReactionTime';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const Button = styled.button`
  background-color: #4a5680;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #5a6a9f;
  }
`;

interface GameProps {
  onScoreUpdate: (score: number) => void;
  onNextGame: () => void;
  totalGames: number;
  currentGameIndex: number;
  scores: number[];
}

// Define a type for game component
type GameComponent = React.FC<GameProps>;

const gameOrder: { component: GameComponent; name: string }[] = [
  { component: DiscountDash, name: 'Discount Dash' },
  { component: Dash2, name: 'Dash 2' },
  { component: ReactionTime, name: 'Reaction Time' },
  // { component: NumberMemory, name: 'Number Memory' },
  { component: PatternRecognition, name: 'Pattern Recognition' },
  { component: FortunesWheel, name: 'Fortunes Wheel' },
 
];

const totalGames = gameOrder.length;

const MiniGamesChallenge: React.FC = () => {
  const [currentGameIndex, setCurrentGameIndex] = useState(0);
  const [startGame, setStartGame] = useState(false);
  const [scores, setScores] = useState<number[]>([]);
  const [gameToken, setGameToken] = useState<string | null>(null);
  const [weeklyLeaderboard, setWeeklyLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [monthlyLeaderboard, setMonthlyLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [myRank, setMyRank] = useState<RankResponse | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const state = location.state as { gameToken?: string };
    if (state && state.gameToken) {
      setGameToken(state.gameToken);
    } else {
      // Redirect to game selection if no token is present
      navigate('/game-selection');
    }
    fetchLeaderboards();
  }, [location, navigate]);

  const fetchLeaderboards = async () => {
    try {
      const weeklyData = await getWeeklyLeaderboard();
      setWeeklyLeaderboard(weeklyData.highScores);

      const monthlyData = await getMonthlyLeaderboard();
      setMonthlyLeaderboard(monthlyData.highScores);
    } catch (error) {
      console.error('Error fetching leaderboards:', error);
    }
  };

  const handleStartGame = () => {
    if (gameToken) {
      setStartGame(true);
      setScores([]);
      setCurrentGameIndex(0);
    } else {
      console.error('No game token available');
      navigate('/game-selection');
    }
  };

  const handleScoreUpdate = (score: number) => {
    setScores((prevScores) => {
      const newScores = [...prevScores, score];
      console.log("Updated scores:", newScores);
      
      // Check if this is the last game (Fortune Wheel)
      if (newScores.length === gameOrder.length) {
        // Use the score from Fortune Wheel as the final total score
        finishGame(score);
      }
      
      return newScores;
    });
  };

  const moveToNextGame = () => {
    setCurrentGameIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex >= gameOrder.length) {
        console.log("All games completed");
        // Instead of trying to move to the next game, we'll finish the game here
        const totalScore = scores.reduce((a, b) => a + b, 0);
        finishGame(totalScore);
        return prevIndex; // Keep the index at the last game
      }
      return newIndex;
    });
  };

  const finishGame = async (finalTotalScore: number) => {
    // alert('Final Total Score: ' + finalTotalScore);
    if (!gameToken) {
      console.error('No game token available');
      // alert('No game token available');
      return;
    }

    const previousScoresSum = scores.reduce((a, b) => a + b, 0);
    // alert('Previous Scores Sum: ' + previousScoresSum);
    const fortuneWheelScore = finalTotalScore - previousScoresSum;
    // alert('Fortune Wheel Score: ' + fortuneWheelScore);
    const scoreData: ScoreData = {
      discountDash: scores[0] || 0,
      dash2: scores[1] || 0,
      reactionTime: scores[2] || 0,
      patternRecognition: scores[3] || 0,
      wheelOfFortune: fortuneWheelScore,
      all: finalTotalScore,
    };

    console.log("Submitting scores:", scoreData, "Raw scores:", scores, "Final Total Score:", finalTotalScore);

    // Create a detailed score message

    try {
      await registerScore(scoreData, gameToken);
      const scoreMessage = `
      Game Over! Here are your scores:
      
      Discount Dash: ${scoreData.discountDash}
      Dash 2: ${scoreData.dash2}
      Reaction Time: ${scoreData.reactionTime}
      Pattern Recognition: ${scoreData.patternRecognition}
      Fortunes Wheel: ${scoreData.wheelOfFortune}
      
      Total Score: ${finalTotalScore}
      
      Submitting your score to the leaderboard...
    `;

    // Show the alert with detailed scores
     alert(scoreMessage);
      fetchLeaderboards();
      resetGame();
    } catch (error) {
      console.error('Error registering score:', error);
      alert('Failed to register score. Please try again.');
    }
  };

  const fetchMyRank = async () => {
    try {
      const rankData = await getMyRank('mini-games-challenge');
      setMyRank(rankData);
    } catch (error) {
      console.error('Error fetching rank:', error);
    }
  };

  const resetGame = () => {
    setCurrentGameIndex(0);
    setScores([]);
    setStartGame(false);
    setGameToken(null);
  };

  const CurrentGameComponent = gameOrder[currentGameIndex]?.component;

  return (
    <Container>
      <h1>Mini Games Challenge</h1>
      {!startGame ? (
        <>
          <PreGameInstructions totalGames={totalGames} currentGameIndex={currentGameIndex + 1} />
          <Button onClick={handleStartGame}>Start Mini Games</Button>
        </>
      ) : CurrentGameComponent ? (
        <CurrentGameComponent
          onScoreUpdate={handleScoreUpdate}
          onNextGame={moveToNextGame}
          totalGames={totalGames}
          currentGameIndex={currentGameIndex}
          scores={scores}
        />
      ) : (
        <p>Loading next game...</p>
      )}
      {/* Add this section to display current scores */}
      {startGame && (
        <div>
          <h3>Current Scores:</h3>
          <ul>
            {scores.map((score, index) => (
              <li key={index}>{gameOrder[index].name}: {score}</li>
            ))}
          </ul>
          <p>Total Score: {scores.reduce((a, b) => a + b, 0)}</p>
        </div>
      )}
    </Container>
  );
};

const LeaderboardDisplay: React.FC<{ leaderboard: LeaderboardEntry[] }> = ({ leaderboard }) => (
  <ul>
    {leaderboard.map((entry, index) => (
      <li key={index}>
        {entry.rank}. {entry.name} - {entry.score} (University: {entry.universityName})
      </li>
    ))}
  </ul>
);

export default MiniGamesChallenge;
