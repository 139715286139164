import React from 'react';
import styled from 'styled-components';

const InstructionsContainer = styled.div`
  background-color: #4a5680;
  color: white;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
`;

const Instructions: React.FC<{ text: string }> = ({ text }) => {
  return (
    <InstructionsContainer>
      <h4>Instructions:</h4>
      <p>{text}</p>
    </InstructionsContainer>
  );
};

export default Instructions;
