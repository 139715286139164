import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import DiscountDash from './games/MiniGamesChallenge/DiscountDash';
import GameSelection from './components/GameSelection';
import Header from './components/Header';
import Leaderboard from './components/Leaderboard'; // Imported Leaderboard component
import MiniGamesChallenge from './games/MiniGamesChallenge';
import React from 'react';
import ScoreBreakdown from './components/ScoreBreakdown'; // Import the new component
import styled from 'styled-components';

const AppContainer = styled.div`
  background-color: #2d3250;
  min-height: 100vh;
  color: white;
  display: flex; // Added for flexible layout
  flex-direction: column; // Added for vertical stacking
  align-items: center; // Center items horizontally

  @media (max-width: 768px) { // Media query for responsiveness
    padding: 10px; // Added padding for smaller screens
  }
`;

function App() {
  return (
    <Router>
      <AppContainer>
        <Header />
        <Routes>
          <Route path="/" element={<GameSelection />} />
          <Route path="/mini-games" element={<MiniGamesChallenge />} />
          <Route path="/leaderboard" element={<Leaderboard />} /> {/* Route for Leaderboard component */}
          <Route path="/score-breakdown" element={<ScoreBreakdown />} /> {/* Add route for score breakdown */}
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;
