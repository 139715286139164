import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  background-color: #1e2238;
  padding: 20px; // Added padding for spacing
  margin-bottom: 40px; // Increased margin for more spacing below the header
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.h1`
  margin: 0;
  font-size: 1.5rem;
  margin-right: 2rem; // Added margin-right to increase space between logo and navigation
`;

const Nav = styled.nav`
  display: flex;
  gap: 1rem;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ExternalLink = styled.a`
  color: white;
  text-decoration: none;
  margin: 0 1rem;
  &:hover {
    text-decoration: underline;
  }
`;

const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <Logo>Easelite Games</Logo>
      <Nav>
        <StyledLink to="/">Home</StyledLink>
        <StyledLink to="/leaderboard">Leaderboard</StyledLink>
        <ExternalLink href="https://blog.easelite.com/" target="_blank" rel="noopener noreferrer">
          Blog
        </ExternalLink>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
