import React, { useCallback, useEffect, useState } from 'react';

import { GameProps } from '../../types/GameProps';
import Instructions from '../../components/Instructions';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  max-width: 800px;
  margin: 0 auto;
  background-color: #1e2238;
  color: #e0e0e0;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const GameInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
  background-color: #2a3150;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
`;

const Sequence = styled.p`
  font-size: 1.8rem;
  font-weight: bold;
  margin: 1.5rem 0;
  color: #00fff5;
  text-shadow: 0 0 5px rgba(0, 255, 245, 0.5);

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 600px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }
`;

const Button = styled.button`
  font-size: 1rem;
  padding: 0.6rem 1rem;
  background-color: #4a5680;
  color: #e0e0e0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: #5a6a9f;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(90, 106, 159, 0.5);
  }
  &:disabled {
    background-color: #2a2a3a;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.5rem 0.8rem;
  }
`;

const ResultContainer = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #2a3150;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Timer = styled.div`
  font-size: 1.2rem;
  color: #ff9800;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PatternRecognition: React.FC<GameProps> = ({ onScoreUpdate, onNextGame, totalGames, currentGameIndex }) => {
  const [sequence, setSequence] = useState<number[]>([]);
  const [options, setOptions] = useState<number[]>([]);
  const [round, setRound] = useState(1);
  const [roundScore, setRoundScore] = useState(0);
  const [roundTime, setRoundTime] = useState(0);
  const [isRoundComplete, setIsRoundComplete] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [roundScores, setRoundScores] = useState<number[]>([]);
  const [timeLeft, setTimeLeft] = useState(0);

  const generatePattern = useCallback(() => {
    const start = Math.floor(Math.random() * 20) + 1;
    const length = round === 3 ? 10 : round === 2 ? 8 : 6;
    const patternType = Math.floor(Math.random() * (round === 3 ? 6 : 4)); // More pattern types in the final round

    let newSequence: number[];
    let correctAnswer: number;

    switch (patternType) {
      case 0: // Arithmetic sequence (harder)
        const step = Math.floor(Math.random() * 10) + (round * 2);
        newSequence = Array.from({ length }, (_, i) => start + i * step);
        correctAnswer = start + length * step;
        break;
      case 1: // Geometric sequence (harder)
        const factor = Math.floor(Math.random() * 3) + 2;
        newSequence = Array.from({ length }, (_, i) => start * Math.pow(factor, i));
        correctAnswer = start * Math.pow(factor, length);
        break;
      case 2: // Fibonacci-like sequence
        newSequence = [start, start + round];
        for (let i = 2; i < length; i++) {
          newSequence.push(newSequence[i-1] + newSequence[i-2]);
        }
        correctAnswer = newSequence[length-1] + newSequence[length-2];
        break;
      case 3: // Quadratic sequence
        const a = round;
        const b = Math.floor(Math.random() * 5) + 1;
        newSequence = Array.from({ length }, (_, i) => a * i * i + b * i + start);
        correctAnswer = a * length * length + b * length + start;
        break;
      case 4: // Exponential sequence (only in round 3)
        const base = Math.floor(Math.random() * 3) + 2;
        newSequence = Array.from({ length }, (_, i) => Math.pow(base, i) + start);
        correctAnswer = Math.pow(base, length) + start;
        break;
      case 5: // Alternating sequence (only in round 3)
        const seq1 = Math.floor(Math.random() * 5) + 1;
        const seq2 = Math.floor(Math.random() * 5) + 6;
        newSequence = Array.from({ length }, (_, i) => i % 2 === 0 ? start + i * seq1 : start + i * seq2);
        correctAnswer = length % 2 === 0 ? start + length * seq1 : start + length * seq2;
        break;
      default:
        newSequence = [];
        correctAnswer = 0;
    }

    setSequence(newSequence);
    const wrongOptions = [
      Math.abs(correctAnswer - Math.floor(correctAnswer * 0.1)),
      Math.abs(correctAnswer + Math.floor(correctAnswer * 0.1)),
      Math.abs(correctAnswer + Math.floor(correctAnswer * 0.2)),
      Math.abs(correctAnswer - Math.floor(correctAnswer * 0.2)),
      Math.abs(correctAnswer + Math.floor(correctAnswer * 0.15))
    ];
    setOptions(shuffleArray([...wrongOptions, correctAnswer]));
  }, [round]);

  const getRoundTimeLimit = useCallback((round: number) => {
    switch (round) {
      case 1: return 30;
      case 2: return 60;
      case 3: return 90;
      default: return 30;
    }
  }, []);

  useEffect(() => {
    generatePattern();
    setStartTime(Date.now());
    const timeLimit = getRoundTimeLimit(round);
    setTimeLeft(timeLimit);

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          handleAnswer(NaN); // Force incorrect answer if time runs out
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [round, generatePattern, getRoundTimeLimit]);

  const shuffleArray = (array: number[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const calculateRoundScore = (isCorrect: boolean, timeTaken: number) => {
    const maxRoundScore = round === 3 ? 600 : round === 2 ? 500 : 400;
    const timeLimit = getRoundTimeLimit(round);

    if (!isCorrect) return 0;

    const timeScore = Math.max(0, 1 - (timeTaken / timeLimit));
    return Math.round(maxRoundScore * timeScore);
  };

  const handleAnswer = (answer: number) => {
    const timeTaken = (Date.now() - startTime) / 1000;
    const correctAnswer = sequence[sequence.length - 1] + (sequence[1] - sequence[0]);
    const isCorrect = answer === correctAnswer;

    const score = calculateRoundScore(isCorrect, timeTaken);

    setRoundScore(score);
    setRoundTime(timeTaken);
    setIsRoundComplete(true);
    setRoundScores(prevScores => [...prevScores, score]);
  };

  const handleNextRound = () => {
    if (round < 3) {
      setRound(prevRound => prevRound + 1);
      setIsRoundComplete(false);
      setRoundScore(0);
      setStartTime(Date.now());
    } else {
      const totalScore = roundScores.reduce((a, b) => a + b, 0);
      console.log("Pattern Recognition total score:", totalScore);
      onScoreUpdate(totalScore);
      onNextGame();
    }
  };

  return (
    <Container>
      <h2 style={{ color: '#00fff5', marginBottom: '1rem' }}>Pattern Recognition - Round {round}</h2>
      <Instructions text="Identify the pattern in the sequence and select the next number. Speed matters - faster correct answers earn more points!" />
      <Instructions text="The game gets progressively harder with each round, so stay focused and think quickly!" />
      <Instructions text={`You have ${totalGames - currentGameIndex - 1} games left to play!`} />
      <GameInfo>
        <span>Current Round: {round}</span>
        <span>Round Score: {roundScore}</span>
        <span>Total Score: {roundScores.reduce((a, b) => a + b, 0)}</span>
      </GameInfo>
      <Timer>Time left: {timeLeft} seconds</Timer>
      <Sequence>Sequence: {sequence.join(', ')}, ?</Sequence>
      <ButtonContainer>
        {options.map((option, index) => (
          <Button key={index} onClick={() => handleAnswer(option)} disabled={isRoundComplete}>
            {option}
          </Button>
        ))}
      </ButtonContainer>
      {isRoundComplete && (
        <ResultContainer>
          <h4 style={{ color: '#00fff5' }}>Round {round} Complete!</h4>
          <p>Score: {roundScore}</p>
          <p>Time: {roundTime.toFixed(2)} seconds</p>
          <Button onClick={handleNextRound} style={{ marginTop: '1rem' }}>
            {round < 3 ? 'Next Round' : 'Finish Game'}
          </Button>
        </ResultContainer>
      )}
    </Container>
  );
};

export default PatternRecognition;
