import { AnimalMatch, CountryMatch, getEducationalMatches } from '../../api/easeliteApi';
import { FaHeart, FaHeartBroken, FaPaw, FaTree } from 'react-icons/fa';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import Instructions from '../../components/Instructions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
  background-color: #1e2238;
  color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h2`
  color: #00fff5;
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const GameInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-bottom: 1rem;
  background-color: #16213e;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
`;

const LivesIndicator = styled.div`
  display: flex;
  gap: 5px;
`;

const GameBoard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ListContainer = styled.div`
  width: 45%;
  background-color: #0f3460;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const ListTitle = styled.h3`
  color: #00fff5;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(5px); }
  50% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

const Item = styled.div<{ selected?: boolean; shake?: boolean }>`
  padding: 0.75rem;
  margin: 0.5rem 0;
  background-color: ${(props) => (props.selected ? '#5a6a9f' : '#4a5680')};
  cursor: pointer;
  animation: ${props => props.shake ? shakeAnimation : 'none'} 0.5s;
  transition: all 0.3s ease;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #5a6a9f;
    transform: scale(1.05);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const Button = styled.button`
  background-color: #e94560;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  margin: 1rem 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  &:hover {
    background-color: #ff6b6b;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(233, 69, 96, 0.4);
  }
`;

interface Match {
  name: string;
  code: string;
}

const Dash2: React.FC<{
  onScoreUpdate: (score: number) => void;
  onNextGame: () => void;
  totalGames: number;
  currentGameIndex: number;
  scores: number[];
}> = ({ onScoreUpdate, onNextGame, totalGames, currentGameIndex, scores }) => {
  const [matches, setMatches] = useState<Match[]>([]);
  const [score, setScore] = useState(0);
  const [lives, setLives] = useState(3);
  const [time, setTime] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);
  const [shakeItem, setShakeItem] = useState<string | null>(null);
  const [round, setRound] = useState(1);
  const [roundScores, setRoundScores] = useState<number[]>([]);
  const [shuffledMatches, setShuffledMatches] = useState<Match[]>([]);
  const [lastShuffleTime, setLastShuffleTime] = useState(0);

  const shuffleArray = useCallback((array: any[]) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }, []);

  const shuffleDurations = useMemo(() => [900, 1200, 1400, 2100, 1300], []);
  const [currentShuffleDurationIndex, setCurrentShuffleDurationIndex] = useState(0);

  useEffect(() => {
    startGame();
  }, []);

  useEffect(() => {
    if (round > 1) {
      // Immediate shuffle for rounds 2 and 3
      setShuffledMatches(shuffleArray([...matches]));
      setLastShuffleTime(Date.now());
    }

    const shuffleInterval = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - lastShuffleTime >= 15000) { // 15 seconds
        setShuffledMatches(shuffleArray([...matches]));
        setLastShuffleTime(currentTime);
      }
    }, 1000); // Check every second

    return () => clearInterval(shuffleInterval);
  }, [matches, shuffleArray, round]);

  useEffect(() => {
    if (!gameOver) {
      const timer = setInterval(() => setTime((prev) => prev + 1), 1000);
      return () => clearInterval(timer);
    }
  }, [gameOver]);

  const startGame = async () => {
    let fetchedMatches: Match[] = [];
    let count = round === 1 ? 6 : round === 2 ? 10 : 15;

    const response = await getEducationalMatches('animals', count);
    fetchedMatches = response.map(match => ({
      name: (match as AnimalMatch).animal,
      code: (match as AnimalMatch).habitat
    }));
    if (round > 1) {
      // Immediate shuffle for rounds 2 and 3
      setShuffledMatches(shuffleArray(fetchedMatches));
      setLastShuffleTime(Date.now());
    } else {
      setShuffledMatches(fetchedMatches);
    }
    setMatches(fetchedMatches);
    setScore(0);
    setLives(3);
    setTime(0);
    setGameOver(false);
    setSelectedMatch(null);
    setShakeItem(null);
  };

  const calculateRoundScore = () => {
    const itemCount = round === 1 ? 6 : round === 2 ? 6 : 10;
    const maxScore = round === 1 ? 500 : round === 2 ? 1000 : 1000;
    const minScore = round >=2 ? 200 : 10;
    
    // Base score calculation (more punitive for mistakes)
    const correctMatches = Math.max(0, score / 10); // Each correct match is worth 10 points
    const baseScore = (correctMatches / itemCount) * maxScore * 0.7;
    
    // Time penalty calculation
    const expectedTime = itemCount * 3; // 3 seconds per item as benchmark
    const timePenalty = Math.max(0, (time - expectedTime) / expectedTime) * maxScore * 0.2;
    
    // Lives penalty calculation
    const livesPenalty = (3 - lives) * (maxScore * 0.05); // 5% penalty per lost life
    
    // Calculate final score
    let finalScore = Math.round(baseScore - timePenalty - livesPenalty);
    
    // Add the minimum score to whatever they scored
    finalScore = Math.min(finalScore + minScore, maxScore);
    
    return finalScore;
  };

  const handleMatchClick = (match: Match) => {
    setSelectedMatch(match);
  };

  const handleCodeClick = (code: string) => {
    if (selectedMatch) {
      if (selectedMatch.code === code) {
        setScore((prev) => prev + 10);
        const newMatches = matches.filter((m) => m !== selectedMatch);
        setMatches(newMatches);
      } else {
        setScore((prev) => Math.max(0, prev - 5));
        setLives((prev) => prev - 1);
        setShakeItem(code);
        setTimeout(() => setShakeItem(null), 500);
      }
      setSelectedMatch(null);
    }

    if (lives === 1 || matches.length === 1) {
      const roundScore = calculateRoundScore();
      setRoundScores((prev) => [...prev, roundScore]);

      if (round < 3) {
        setRound((prev) => prev + 1);
        startGame();
      } else {
        setGameOver(true);
        const finalScore = [...roundScores, roundScore].reduce((a, b) => a + b, 0);
        onScoreUpdate(finalScore);
      }
    }
  };

  if (gameOver) {
    return (
      <Container>
        <h2>Game Over</h2>
        <p>Round 1 Score: {roundScores[0]}</p>
        <p>Round 2 Score: {roundScores[1]}</p>
        <p>Round 3 Score: {roundScores[2]}</p>
        <p>Final Score: {roundScores.reduce((a, b) => a + b, 0)}</p>
        <Button onClick={onNextGame}>Next Game</Button>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Animal Habitat Match - Round {round}</Title>
      <Instructions text="Match animals with their habitats! Speed and accuracy are key!" />
      <Instructions text="Click an animal, then its habitat. Be quick - time affects your score and habitats shuffle " />
      <Instructions text={`You have ${totalGames - currentGameIndex} games left to play!`} />
      <GameInfo>
        <span>Score: {score}</span>
        <LivesIndicator>
          {[...Array(3)].map((_, index) => (
            index < lives ? <FaHeart key={index} color="#e94560" /> : <FaHeartBroken key={index} color="#4a5680" />
          ))}
        </LivesIndicator>
        <span>Time: {time}s</span>
      </GameInfo>
      <GameBoard>
        <ListContainer>
          <ListTitle><FaPaw /> Animals</ListTitle>
          {matches.map((match) => (
            <Item
              key={match.name}
              onClick={() => handleMatchClick(match)}
              selected={selectedMatch === match}
            >
              {match.name}
            </Item>
          ))}
        </ListContainer>
        <ListContainer>
          <ListTitle><FaTree /> Habitats</ListTitle>
          {shuffledMatches.map((match) => (
            <Item
              key={match.code}
              onClick={() => handleCodeClick(match.code)}
              shake={shakeItem === match.code}
            >
              {match.code}
            </Item>
          ))}
        </ListContainer>
      </GameBoard>
    </Container>
  );
};

export default Dash2;
