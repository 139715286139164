import React, { useEffect, useState } from 'react';

import Instructions from '../../components/Instructions';
import { Wheel } from 'react-custom-roulette';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #1a1a2e;
  color: #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  max-width: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Button = styled.button`
  background-color: #0f3460;
  color: #e0e0e0;
  border: none;
  padding: 0.75rem 1.5rem;
  margin: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  border-radius: 4px;

  &:hover {
    background-color: #e94560;
  }

  &:active {
    transform: scale(0.98);
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
`;

const ScoreDisplay = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  margin: 1rem 0;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0.25rem;
  font-size: 0.8rem;
`;

const ColorBox = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  background-color: ${props => props.color};
  margin-right: 0.25rem;
`;

interface FortunesWheelProps {
  onScoreUpdate: (score: number) => void;
  onNextGame: () => void;
  totalGames: number;
  currentGameIndex: number;
  scores: number[]; // Change this to number[]
}

const baseData = [
  { option: 'Safe', points: 50, style: { backgroundColor: 'green', textColor: 'white' } },
  { option: 'Good', points: 100, style: { backgroundColor: 'blue', textColor: 'white' } },
  { option: 'Great', points: 150, style: { backgroundColor: 'purple', textColor: 'white' } },
  { option: 'Risky', points: -50, style: { backgroundColor: 'red', textColor: 'white' } },
  { option: 'Risky+', points: -200, style: { backgroundColor: 'orange', textColor: 'white' } },
];

const FortunesWheel: React.FC<FortunesWheelProps> = ({ onScoreUpdate, onNextGame, totalGames, currentGameIndex, scores }) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [score, setScore] = useState(0);
  const [round, setRound] = useState(1);
  const [data, setData] = useState(baseData);
  const [gameEnded, setGameEnded] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [finalScore, setFinalScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);

  useEffect(() => {
    const previousScoresSum = scores.reduce((sum, score) => sum + score, 0);
    setTotalScore(previousScoresSum);
  }, [scores]);

  useEffect(() => {
    if (round === 2) {
      setData([...baseData, { option: 'Jackpot', points: 300, style: { backgroundColor: 'gold', textColor: 'black' } }]);
    } else if (round === 3) {
      setData([
        ...baseData,
        { option: 'Jackpot', points: 300, style: { backgroundColor: 'gold', textColor: 'black' } },
        { option: 'Wipeout', points: -800, style: { backgroundColor: 'black', textColor: 'white' } },
        { option: 'Ultimate', points: 800, style: { backgroundColor: 'pink', textColor: 'black' } },
        { option: 'Major Loss', points: -425, style: { backgroundColor: 'darkred', textColor: 'white' } },
      ]);
    }
  }, [round]);

  const handleSpinClick = () => {
    let newPrizeNumber;
    if (round === 1) {
      // 80% chance of landing on a positive point in round 1
      newPrizeNumber = Math.random() < 0.8 
        ? Math.floor(Math.random() * 3) // Safe, Good, or Great
        : Math.floor(Math.random() * 2) + 3; // Risky or Risky+
    } else if (round === 3) {
      // 65% chance of landing on a negative point in round 3
      newPrizeNumber = Math.random() < 0.65
        ? [3, 4, 6, 8][Math.floor(Math.random() * 4)] // Risky, Risky+, Wipeout, or Major Loss
        : [0, 1, 2, 5, 7][Math.floor(Math.random() * 5)]; // Other options
    } else {
      newPrizeNumber = Math.floor(Math.random() * data.length);
    }
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  const handleSpinStop = () => {
    setMustSpin(false);
    const newScore = updateScore(prizeNumber);
    setScore(newScore);
    setTotalScore(prevTotal => prevTotal + newScore);
    
    if (round === 1 || round === 2) {
      setShowOptions(true);
    } else if (round === 3) {
      endGame(newScore);
    }
  };

  const updateScore = (segmentIndex: number): number => {
    const segment = data[segmentIndex];
    let points = segment.points;
    if (round === 3 && segment.option === 'Ultimate') {
      points = Math.random() < 0.002 ? 800 : 0; // 1 in 500 chance for Ultimate points
    }
    const newScore = score + points;
    setScore(newScore);
    setTotalScore(prevTotal => prevTotal + points);
    return newScore;
  };

  const handleWalkAway = () => {
    endGame(score);
  };

  const handleContinue = () => {
    setShowOptions(false);
    setRound(prevRound => prevRound + 1);
    // Reset for the next round
    setMustSpin(false);
    setPrizeNumber(0);
  };

  const endGame = (finalWheelScore: number) => {
    setGameEnded(true);
    const finalTotalScore = totalScore + finalWheelScore;
    setFinalScore(finalTotalScore);
    onScoreUpdate(finalTotalScore);
  };

  const handleMoveToNextGame = () => {
    // Instead of calling onNextGame, we'll call onScoreUpdate with the final score
    onNextGame();
  };

  return (
    <Container>
      <h2>Fortunes Wheel - Round {round}</h2>
      <Instructions text="Spin the wheel and test your luck! Each round increases the stakes." />
      <Instructions text="After each spin, decide to walk away or continue. Be cautious - higher rewards come with greater risks!" />
      <Instructions text={`You have ${totalGames - currentGameIndex} games left to play!`} />
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={data}
        onStopSpinning={handleSpinStop}
        outerBorderColor="#ccc"
        outerBorderWidth={2}
        innerBorderColor="#ccc"
        radiusLineColor="#ccc"
        radiusLineWidth={1}
        textDistance={60}
        fontSize={14}
      />
      {!mustSpin && !gameEnded && !showOptions && (
        <Button onClick={handleSpinClick}>
          {round === 3 ? "Final Spin!" : "Spin the Wheel"}
        </Button>
      )}
      {mustSpin && <p>Spinning...</p>}
      <ScoreDisplay>Current Wheel Score: {score}</ScoreDisplay>
      <ScoreDisplay>Total Score (including previous games): {totalScore}</ScoreDisplay>
      
      {showOptions && (
        <div>
          <p>Do you want to walk away with your current score or continue to the next round?</p>
          <Button onClick={handleWalkAway}>Walk Away</Button>
          <Button onClick={handleContinue}>Continue to {round === 2 ? "Final Round" : "Next Round"}</Button>
        </div>
      )}

      {gameEnded && (
        <div>
          <h3>Game Over!</h3>
          <p>Fortunes Wheel Score: {score}</p>
          <p>Total Score (including previous games): {finalScore}</p>
          <Button onClick={handleMoveToNextGame}>Finish Game</Button>
        </div>
      )}
      
      <Legend>
        {data.map((item, index) => (
          <LegendItem key={index}>
            <ColorBox color={item.style.backgroundColor} />
            {item.option}: {item.points} points
          </LegendItem>
        ))}
      </Legend>
    </Container>
  );
};

export default FortunesWheel;
